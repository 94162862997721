import { UserInfo, ConversationRequest } from "./models";

export async function conversationApi(options: ConversationRequest, abortSignal: AbortSignal, dalle: string, user: string, model: string, sessionid: string, question: string, accessToken: string|undefined): Promise<Response> {
    const formData = new FormData();
    
    formData.append("messages", JSON.stringify(options.messages));

    if (options.file) {
        formData.append("file", options.file, options.file.name);
    }

    formData.append("dalle", JSON.stringify(dalle))
    formData.append("user", JSON.stringify(user))
    formData.append("model", JSON.stringify(model))
    formData.append("sessionid", JSON.stringify(sessionid))
    formData.append("question", JSON.stringify(question))

    const response = await fetch("/conversation", {
        method: "POST",
        headers: {
            Authorization: `Bearer ${accessToken}`
        },
        body: formData,
        signal: abortSignal
    });

    return response;
}

function countTokensInTSX(inputString: string): string {
    const tokenRegex = /\s+|\/\/.*|\/\*[\s\S]*?\*\/|<\/?[^>]+>|=>|[\w\u00A0-\uFFFF$]+|./g;
    const tokens = inputString.match(tokenRegex);
    const tokenCount = tokens ? tokens.length : 0;
    
    return tokenCount.toString();
}

export async function DALLE(Prompt: string): Promise<string> {
    try {
        const response = await fetch("/dalle", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                prompt: Prompt
            })
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const responseData = await response.json();
        const url = responseData.url;

        if (!url) {
            throw new Error("Invalid response from server");
        }

        return url;
    } catch (error) {
        console.error("Error while sending the request:", error);
        throw error;
    }
}


export async function saveLog(User: string, Question: string, Answer: string, Model: string, Filename: string, Token: string, accessToken: string|undefined): Promise<Response> {
    const apiUrl = "/savelog"; // Reemplaza con la URL correcta de la API

    const requestBody = {
        user: User,
        question: Question,
        answer: Answer,
        tokens: countTokensInTSX(Question+" "+Answer),
        model: Model,
        filename: Filename,
        session: Token,
    };

    try {
        const response = await fetch(apiUrl, {
            method: "POST",
            headers: {
                "Authorization": `Bearer ${accessToken}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify(requestBody)
        });

        return response;
    } catch (error) {
        console.error("Error while sending the request:", error);
        throw error;
    }
}